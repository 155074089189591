var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        {
          directives: [
            {
              name: "clickoutside",
              rawName: "v-clickoutside",
              value: _vm.clickoutside,
              expression: "clickoutside"
            }
          ],
          staticClass: "dialogBody",
          style: _vm.style
        },
        _vm._l(_vm.optionals, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "optionalItem",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.clickItem(index)
                }
              }
            },
            [_vm._v("\n    " + _vm._s(item.title) + "\n  ")]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }