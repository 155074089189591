<template>
  <div
    class="dialogBody"
    v-if="visible"
    :style="style"
    v-clickoutside="clickoutside"
  >
    <div
      class="optionalItem"
      v-for="(item, index) in optionals"
      :key="index"
      @click.stop="clickItem(index)"
    >
      {{ item.title }}
    </div>
  </div>
</template>
<script>
export default {
  name: "AddEmailToContact",
  data() {
    return {
      optionals: [
        { title: this.$t("sendEmail") },
        { title: this.$t("addToIMAddressBook") },
      ],
    };
  },
  computed: {
    relevantInfo() {
      return this.$store.state.melinked_email.addEmailToContactPopInfo;
    },
    visible() {
      return this.relevantInfo ? true : false;
    },
    style() {
      return {
        top: `${this.relevantInfo.y}px`,
        left: `${this.relevantInfo.x}px`,
      };
    },
    user() {
      return this.relevantInfo.melinkedUser;
    },
  },
  methods: {
    clickoutside() {
      this.$store.commit("setAddEmailToContactPopInfo", null);
    },
    clickItem(index) {
      switch (index) {
        case 0:
          this.$store.commit("setOpenEmailSendView", {
            open: true,
            type: "send",
            emailAddress: this.relevantInfo.email,
          });
          this.clickoutside();
          break;
        case 1:
          this.$store.commit("setContactDialogData", {
            dialog: true,
            type: "add",
            data: {
              email: this.relevantInfo.email,
            },
          });
          this.clickoutside();
          break;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.dialogBody
  top: 0;
  left: 0;
  z-index: 2002;
  position: absolute;
  width: 183px;
  height: 72px;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 1px 1px 8px 0px rgba(0,0,0,0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .optionalItem
    height: 36px;
    width: 100%;
    background: #FFFFFF;
    box-sizing: border-box;
    padding: 0 12px;
    line-height: 36px;
    font-size: 14px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select none;
    cursor pointer;
    &:hover
      background: #F1F1F1;
</style>
